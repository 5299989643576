import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import Cookies from "js-cookie";
const token = Cookies.get("jwtToken");
// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://quiz-ws.azurewebsites.net/api`;

const StartQuiz = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();

  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [showExitModal, setShowExitModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await fetch(
          `http://localhost:3000/api/quizzes/attempt/quiz/${quizId}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setQuiz({
          title: data.title || "Quiz",
          questions: data.questions.map((q, index) => ({
            id: index,
            question: q.questionText,
            questionImage: q.questionImage || "",
            options: q.options.map((option) => option.optionText),
            correctAnswer: q.options.findIndex(
              (option) => option.label === q.correctAnswer
            ),
          })),
        });
        setTimer(data.timeLimit || 300); // Set timer if provided, else default to 5 minutes
      } catch (error) {
        console.error("Failed to fetch quiz:", error);
        setError(error.message);
      }
    };

    fetchQuiz();
  }, [quizId]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  const handleAnswerClick = (optionIndex) => {
    const newSelectedOptions = [...selectedOptions];
    if (newSelectedOptions[currentQuestionIndex] === optionIndex) {
      newSelectedOptions[currentQuestionIndex] = undefined;
    } else {
      newSelectedOptions[currentQuestionIndex] = optionIndex;
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleNext = async () => {
    if (selectedOptions[currentQuestionIndex] !== undefined) {
      if (
        selectedOptions[currentQuestionIndex] ===
        quiz.questions[currentQuestionIndex].correctAnswer
      ) {
        setScore(score + 1);
      }

      if (!answeredQuestions.includes(currentQuestionIndex)) {
        setAnsweredQuestions([...answeredQuestions, currentQuestionIndex]);
      }
    }
    if (currentQuestionIndex + 1 < quiz.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      await confirmFinish(true); // Automatically finish when the last question is answered
    }
  };

  const handleSkip = () => {
    if (currentQuestionIndex + 1 < quiz.questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setCompleted(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setSelectedOptions([]);
    setAnsweredQuestions([]);
    setCompleted(false);
    setTimer(300); // Reset timer to 5 minutes
  };

  const handleQuestionSelect = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleExit = () => {
    setShowExitModal(true);
  };

  const confirmExit = (confirm) => {
    if (confirm) {
      navigate("/"); // Navigate back to the quizzes
    } else {
      setShowExitModal(false);
    }
  };

  const confirmFinish = async (confirm) => {
    if (confirm) {
      try {
        const answers = selectedOptions
          .map((optionIndex, questionIndex) => {
            const answer =
              optionIndex !== undefined
                ? String.fromCharCode(65 + optionIndex)
                : null;

            return {
              questionNo: questionIndex ,
              answer: answer,
            };
          })
          .filter((ans) => ans.answer !== null); // Filter out unanswered questions

        // Make the POST request
        const response = await fetch(
          `${apiUrl}/quizzes/attempt/quiz/${quizId}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ answers }),
          }
        );

        // Handle the response
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        // Update the score and set the quiz as completed
        setScore(result.score);
        setCompleted(true);
      } catch (error) {
        console.error("Failed to submit quiz:", error);
        setError(error.message);
      }
    }

    setShowFinishModal(false); // Close the finish modal
  };

  useEffect(() => {
    if (completed) return;

    const interval = setInterval(() => {
      setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    if (timer === 0 && !completed) {
      confirmFinish(true); // Automatically finish when time's up
    }

    return () => clearInterval(interval);
  }, [timer, completed]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!quiz) {
    return <p>Loading quiz...</p>;
  }

  const totalQuestions = quiz.questions.length;
  const unansweredQuestionsCount = totalQuestions - answeredQuestions.length;

  const progressPercentage = Math.floor(
    (answeredQuestions.length / totalQuestions) * 100
  );

  const isImage = (url) => /\.(jpg|jpeg|png|gif|bmp)$/i.test(url);

  return (
    <div className="quiz-container animated-bg start-quiz-main-container">
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <div className="quiz-header">
        <h2>{quiz.title}</h2>
        <div className="timer-container">
          <p className="timer">Time Left: {formatTime(timer)}</p>
        </div>
      </div>

      {!completed ? (
        <div className="quiz-content">
          <div className="question-section animated">
            <div className="question question-and-skip-alignment">
              <h3>{quiz.questions[currentQuestionIndex]?.question}</h3>
              {quiz.questions[currentQuestionIndex]?.questionImage && (
                <img
                  src={quiz.questions[currentQuestionIndex].questionImage}
                  alt="Question"
                  className="quiz-image"
                />
              )}
              <button className="skip-button" onClick={handleSkip}>
                Skip
              </button>
            </div>
            <div className="options animated-fade">
              {quiz.questions[currentQuestionIndex]?.options.map(
                (option, index) => (
                  <button
                    key={index}
                    className={`option-btn ${
                      selectedOptions[currentQuestionIndex] === index
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleAnswerClick(index)}
                  >
                    {isImage(option) ? (
                      <img
                        src={option}
                        alt={`Option ${index + 1}`}
                        className="quiz-image"
                      />
                    ) : (
                      option
                    )}
                  </button>
                )
              )}
            </div>
            <div className="navigation-buttons">
              <button
                className="nav-btn"
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
              >
                Prev
              </button>

              <button
                className="nav-btn"
                onClick={handleNext}
                disabled={selectedOptions[currentQuestionIndex] === undefined}
              >
                {currentQuestionIndex + 1 === quiz.questions.length
                  ? "Finish"
                  : "Next"}
              </button>
            </div>
          </div>

          <div className="side-panel">
            <div className="question-status">
              <p>
                Question {currentQuestionIndex + 1} / {totalQuestions}
              </p>
              <p>Answered: {answeredQuestions.length}</p>
              <p>Unanswered: {unansweredQuestionsCount}</p>
            </div>
            <div className="question-numbers">
              {quiz.questions.map((_, index) => {
                const isAnswered = answeredQuestions.includes(index);
                return (
                  <button
                    key={index}
                    className={`question-number-btn ${
                      index === currentQuestionIndex ? "active" : ""
                    } ${isAnswered ? "answered" : ""}`}
                    onClick={() => handleQuestionSelect(index)}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
            <button
              className="nav-btn finish-btn"
              onClick={() => setShowFinishModal(true)} // Show the finish modal
            >
              End Test
            </button>
          </div>
        </div>
      ) : (
        <div className="quiz-completion animated">
          <h4>Quiz Completed!</h4>
          <h3>
            Your Score: {score} / {totalQuestions}
          </h3>
          <button
            className="restart-btn animated-button"
            onClick={() => navigate("/dashboard")}
          >
            HOME
          </button>
        </div>
      )}

      <button className="nav-btn" onClick={handleExit}>
        Back to Quizzes
      </button>

      {showExitModal && (
        <div className="exit-modal">
          <div className="modal-content">
            <p>Are you sure you want to quit the quiz?</p>
            <div className="modal-actions">
              <button className="confirm-btn" onClick={() => confirmExit(true)}>
                Yes
              </button>
              <button className="cancel-btn" onClick={() => confirmExit(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showFinishModal && (
        <div className="exit-modal">
          <div className="modal-content">
            <p>Are you sure you want to finish the quiz?</p>
            <div className="modal-actions">
              <button
                className="confirm-btn"
                onClick={() => confirmFinish(true)}
              >
                Yes
              </button>
              <button
                className="cancel-btn"
                onClick={() => confirmFinish(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StartQuiz;
