import { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://quiz-ws.azurewebsites.net/api`;

const Home = () => {
  const [firstName, setFirstName] = useState("");
  const [modules, setModules] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [completedModules, setCompletedModules] = useState([]);
  const [completedQuizzes, setCompletedQuizzes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          console.error("No JWT token found");
          return;
        }

        const response = await fetch(`${apiUrl}/user`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          console.error("Failed to fetch user details:", response.status, response.statusText);
          return;
        }

        const userData = await response.json();
        setFirstName(userData.firstName);
        setCompletedModules(userData.completedModules.map(module => module.moduleId));
        setCompletedQuizzes(userData.completedQuizzes);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const [totalQuizzes, setTotalQuizzes] = useState(0);
  const [totalModules, setTotalModules] = useState(0);
  const [averageScore, setAverageScore] = useState(0);
  const [passingRate, setPassingRate] = useState(0);

  useEffect(() => {
    setTotalQuizzes(completedQuizzes.length);
    setTotalModules(completedModules.length);
    animateCount(setAverageScore, 85, 1000);
    animateCount(setPassingRate, 90, 1000);
  }, [completedQuizzes.length, completedModules.length]);

  const animateCount = (setCount, target, duration) => {
    let start = 0;
    const step = target / (duration / 20);
    const interval = setInterval(() => {
      start += step;
      if (start >= target) {
        start = target;
        clearInterval(interval);
      }
      setCount(Math.floor(start));
    }, 20);
  };

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          console.error("No JWT token found in localStorage");
          return;
        }

        const response = await fetch(`${apiUrl}/modules`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!response.ok) {
          console.error("Failed to fetch modules:", response.status, response.statusText);
          return;
        }

        const data = await response.json();
        const filteredModules = data.filter(module => module.status !== "hold");
        setModules(filteredModules);
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };

    fetchModules();
  }, []);

  const handleStartQuiz = (moduleId) => {
    setSelectedModuleId(moduleId);
    setIsModalOpen(true);
  };

  const handleAcceptInstructions = () => {
    setIsModalOpen(false);
    navigate(`/modules/${selectedModuleId}`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getStatusLabelClass = (status, moduleId) => {
    if (completedModules.includes(moduleId)) {
      return "status-label attempted";
    }
    switch (status) {
      case "active":
        return "status-label active";
      case "inactive":
        return "status-label inactive";
      case "closed":
        return "status-label closed";
      default:
        return "status-label";
    }
  };

  return (
    <div className="home-container">
      <section className="hero">
        <div className="hero-overlay">
          <h1>
            Hi {firstName}! Welcome to Apollo Learning Management!
          </h1>
          <p>
            Discover quizzes and challenge yourself. Select a Learning Path to
            begin.
          </p>
        </div>
      </section>
      <div className="analytics-container">
        <div className="analytics-box">
          <span
            className="number"
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#2c3e50",
              display: "block",
              marginBottom: "0.5rem",
            }}
          >
            {totalQuizzes}
          </span>
          <span
            className="label"
            style={{
              fontSize: "1rem",
              color: "#7f8c8d",
              textTransform: "uppercase",
              letterSpacing: "1px",
            }}
          >
            Quizzes Taken
          </span>
        </div>
        <div className="analytics-box">
          <span
            className="number"
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#2c3e50",
              display: "block",
              marginBottom: "0.5rem",
            }}
          >
            {totalModules}
          </span>
          <span
            className="label"
            style={{
              fontSize: "1rem",
              color: "#7f8c8d",
              textTransform: "uppercase",
              letterSpacing: "1px",
            }}
          >
            Learning Paths Completed
          </span>
        </div>
        <div className="analytics-box">
          <span
            className="number"
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#2c3e50",
              display: "block",
              marginBottom: "0.5rem",
            }}
          >
            Nurse +
          </span>
          <span
            className="label"
            style={{
              fontSize: "1rem",
              color: "#7f8c8d",
              textTransform: "uppercase",
              letterSpacing: "1px",
            }}
          >
            Top Scorer
          </span>
        </div>
        <div className="analytics-box">
          <span
            className="number"
            style={{
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#2c3e50",
              display: "block",
              marginBottom: "0.5rem",
            }}
          >
            {passingRate}%
          </span>
          <span
            className="label"
            style={{
              fontSize: "1rem",
              color: "#7f8c8d",
              textTransform: "uppercase",
              letterSpacing: "1px",
            }}
          >
            Passing Rate
          </span>
        </div>{" "}
      </div>
      <div className="categories-section">
        <h2 className="section-title">Choose Your Learning Path</h2>
        <div
          className="quiz-categories"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "2rem",
            padding: "2rem",
          }}
        >
          {modules.length > 0 ? (
            modules.map((module) => (
              <div
                key={module.moduleId}
                className="category-card"
                style={{
                  background: "white",
                  borderRadius: "15px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  overflow: "hidden",
                  cursor: "pointer",
                  ":hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <div className="category-content" style={{ padding: "1.5rem" }}>
                  <h3
                    style={{
                      fontSize: "1.5rem",
                      color: "#2c3e50",
                      marginBottom: "1rem",
                      borderBottom: "2px solid #e74c3c",
                      paddingBottom: "0.5rem",
                    }}
                  >
                    {module.title}
                  </h3>
                  <p
                    style={{
                      color: "#666",
                      fontSize: "1rem",
                      lineHeight: "1.6",
                      marginBottom: "1rem",
                    }}
                  >
                    {module.description}
                  </p>
                  <p
                    className={getStatusLabelClass(
                      module.status,
                      module.moduleId
                    )}
                    style={{
                      display: "inline-block",
                      padding: "0.5rem 1rem",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                    }}
                  >
                    {completedModules.includes(module.moduleId)
                      ? "Attempted"
                      : module.status}
                  </p>
                  <br />
                  {!completedModules.includes(module.moduleId) &&
                    module.status !== "closed" && (
                      <button
                        onClick={() => handleStartQuiz(module.moduleId)}
                        style={{
                          background: "#3498db",
                          color: "white",
                          border: "none",
                          padding: "0.8rem 1.5rem",
                          borderRadius: "25px",
                          fontSize: "1rem",
                          fontWeight: "600",
                          cursor: "pointer",
                          transition: "background 0.3s ease",
                          marginTop: "1rem",
                          width: "100%",
                          ":hover": {
                            background: "#2980b9",
                          },
                        }}
                      >
                        Start Learning
                      </button>
                    )}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                padding: "2rem",
              }}
            >
              <div
                className="spinner-border align-items-center  "
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              {/* <p style={{ 
                fontSize: '1.2rem', 
                color: '#666',
                marginTop: '1rem'
              }}>No modules available.</p> */}
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Learning Path Instructions</h2>
            <p>
              Welcome to the Learning Module! Here are some basic instructions:
            </p>
            <ul>
              <li>You will have a Lessons.</li>
              <li>Each lesson has Learning Content.</li>
              <li>Good luck and have Great Learning!</li>
            </ul>
            <button onClick={handleAcceptInstructions}>Start Learning</button>
            <button onClick={handleCloseModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
