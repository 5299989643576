import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Carousel,
  Badge,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import Cookies from "js-cookie";
import "./index.css";

const token = Cookies.get("jwtToken");

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://quiz-ws.azurewebsites.net/api`;

const StartQuiz = () => {
  const { moduleid } = useParams();
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [showCompletionPopup, setShowCompletionPopup] = useState(false);
  const [showLearningPathCompleted, setShowLearningPathCompleted] =
    useState(false);
  const [bgColor, setBgColor] = useState("#fff");
  const [selectedOptions, setSelectedOptions] = useState({});
const [spinner,setShowSpinner] = useState(false);
  const handleOptionClick = (slideIndex, optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [slideIndex]: optionIndex,
    });
    handleNext();
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/modules/attempt/module/${moduleid}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch quiz data");
        }

        const data = await response.json();
        setQuiz({
          title: data.title,
          description: data.description,
          color: data.content.color,
          slides: data.content.slides.map((slide) => ({
            text: slide.textbox,
            quiz: slide.quiz
              ? {
                  question: slide.quiz.questionText,
                  options: slide.quiz.options.map(
                    (option) => option.optionText
                  ),
                }
              : null,
            image: slide.image,
            imageLink: slide.imageLink,
          })),
        });
        setBgColor(data.content.color || "#fff");
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      }
    };

    fetchQuizData();
  }, [moduleid]);

  const handleNext = () => {
    if (currentSlideIndex + 1 < quiz.slides.length) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      setCompleted(true);
      setShowCompletionPopup(true);
    }
  };

  const handlePrevious = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const handleSubmitFeedback = async () => {
    setShowSpinner(true);
    try {
      const feedbackResponse = await fetch(`${apiUrl}/modules/feedback`, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          moduleId: moduleid,
          feedbackText: feedback,
          rating: rating,
        }),
      });

      if (!feedbackResponse.ok) {
        throw new Error("Failed to submit feedback");
      }

      const completionResponse = await fetch(
        `${apiUrl}/modules/attempt/module/${moduleid}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!completionResponse.ok) {
        throw new Error("Failed to mark module as completed");
      }

      setShowCompletionPopup(false);
      setShowLearningPathCompleted(true);
    } catch (error) {
      console.error(
        "Error submitting feedback or marking module as completed:",
        error
      );
    }
    setShowSpinner(false);
  };

  const handleOkClick = () => {
    setShowLearningPathCompleted(false);
    navigate("/");
  };
    if (!quiz) {
      return (
              <div className="spinner-border align-items-center position-absolute top-50 start-50 " role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
      );
    }

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100 fullscreen-container"
      style={{ backgroundColor: bgColor, padding: 0 }}
    >
      <Row className="w-100 h-100">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                color: "white",
                zIndex: 10,
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                {quiz.title}
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "white",
                zIndex: 10,
              }}
            >
              <Badge bg="" className="badge-info light">
                ({currentSlideIndex + 1} of {quiz.slides.length})
              </Badge>
            </div>
            <Carousel
              activeIndex={currentSlideIndex}
              onSelect={(selectedIndex) => setCurrentSlideIndex(selectedIndex)}
              interval={null}
              style={{ width: "100%", height: "100%" }}
            >
              {quiz.slides.map((slide, index) => (
                <Carousel.Item key={index} style={{ height: "100%" }}>
                  <div
                    style={{
                      padding: "50px",
                      height: "100vh",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "relative",
                      minHeight: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {slide.text && (
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            borderRadius: "10px",
                            marginBottom: "20px",
                            maxWidth: "80%",
                          }}
                        >
                          {slide.text}
                        </div>
                      )}
                      {slide.image && (
                        <a
                          href={slide.imageLink || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={slide.image}
                            alt={`Slide ${index}`}
                            style={{
                              maxWidth: "100%",
                              marginBottom: "20px",
                              maxHeight: "60%",
                            }}
                          />
                        </a>
                      )}
                      {slide.quiz && (
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <h5 style={{ marginBottom: "20px", color: "white" }}>
                            Question {index + 1}: {slide.quiz.question}
                          </h5>
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {slide.quiz.options.map((option, idx) => (
                              <li key={idx} style={{ marginBottom: "10px" }}>
                                <Button
                                  variant={
                                    selectedOptions[index] === idx
                                      ? "light"
                                      : "outline-light"
                                  }
                                  style={{
                                    width:
                                      window.innerWidth <= 768 ? "100%" : "60%",
                                    textAlign: "left",
                                    backgroundColor:
                                      selectedOptions[index] === idx
                                        ? "rgba(255, 255, 255, 0.5)"
                                        : "rgba(255, 255, 255, 0.2)",
                                    borderColor: "transparent",
                                    color:
                                      selectedOptions[index] === idx
                                        ? "#000"
                                        : "#fff9",
                                    margin: "0 auto",
                                  }}
                                  onClick={() => handleOptionClick(index, idx)}
                                >
                                  <strong>
                                    {String.fromCharCode(65 + idx)}.
                                  </strong>{" "}
                                  {option}
                                </Button>
                              </li>
                            ))}{" "}
                          </ul>
                        </div>
                      )}
                      {index === quiz.slides.length - 1 && (
                        <Button
                          variant="success"
                          onClick={() => setShowCompletionPopup(true)}
                        >
                          Complete Module
                        </Button>
                      )}
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>

      {/* Feedback Modal */}
      <Modal
        show={showCompletionPopup}
        onHide={() => setShowCompletionPopup(false)}
        dialogClassName="modal-90w"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
          textAlign: "center",
          flexDirection: "column",
          position: "relative",
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Feedback</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rating</Form.Label>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={`star ${star <= rating ? 'active' : ''}`}
                    onClick={() => setRating(star)}
                    style={{
                      cursor: 'pointer',
                      fontSize: '24px',
                      color: star <= rating ? '#ffc107' : '#e4e5e9',
                      marginRight: '5px'
                    }}
                  >
                    ★
                  </span>
                ))}
              </div>
            </Form.Group>            {spinner && (
              <div className="spinner-border align-items-center position-absolute top-50 start-50 " role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <Button variant="primary" onClick={handleSubmitFeedback}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Learning Path Completed Modal */}
      <Modal
        show={showLearningPathCompleted}
        onHide={handleOkClick}
        dialogClassName="modal-90w"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
          textAlign: "center",
          flexDirection: "column",
          position: "relative",
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Learning Path Completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Congratulations! You have completed the learning path.</p>
          <Button variant="primary" onClick={handleOkClick}>
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default StartQuiz;
