import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./index.css";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    if (!jwtToken) {
      navigate("/login");
    }
  }, [navigate]);

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="notfound-container">
      <img
        src="https://res.cloudinary.com/dbylngblb/image/upload/v1727874883/404_sndf9l.png"
        alt="404-notfound"
        className="notfound-404-image"
      />
      <button className="notfound-button" onClick={handleGoBack}>
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
